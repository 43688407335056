import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import { Link } from "gatsby"
import SEO from "../components/seo"

const WebsiteMigrationPage = () => (
  <div>
      <SEO 
        title="Website Migration" 
        description="If you’ve ever swapped web hosting providers, then you already know that this procedure can be pretty complicated. 
        Here, at Sheephostingbay, we will spare you all the trouble – we’ll accomplish the tough task and will migrate 
        all your website files, MySQL databases and emails to your brand–new hosting account."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">Website <span className="block text-indigo-600 xl:inline">Migration</span></p>
              <p className="section-after-title">
              If you’ve ever swapped web hosting providers, then you already know that this procedure can be pretty complicated. 
              Here, at Sheephostingbay, we will spare you all the trouble – we’ll accomplish the tough task and will migrate 
              all your website files, MySQL databases and emails to your brand–new hosting account.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="sm:w-2/6 w-full overflow-hidden border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/web-site-migration-01.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: What precisely does web site migration imply and what does it involve?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: The website migration service that we are offering is free of charge (to a certain extent). We’ll transfer your website(s) to our 
                        enterprise–level cloud hosting platform without you having to do anything. We know from personal experience that changing hosting 
                        providers can lead to lost data and unwanted network downtime. That’s why we’ll accomplish all that for you for free!We have 
                        highly qualified administrators whose sole task is to make sure that your website works exactly as it did on your previous 
                        web hosting platform. The administrators are experts in transferring custom–built websites, as well as websites that are 
                        based on famous web apps such as Joomla™ and WordPress.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/transfer-time-02.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: How long will it take for my site to be moved to your platform?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: We try to perform all web site migrations as fast as possible. Nonetheless, we do not have a set time frame for web site migrations. 
                        There are various things that you have to take into consideration – the actual size of your site and the workload of our web site 
                        migration staff, etc. We constantly strive to perform each migration in less than 48 hours. With smaller websites, our staff 
                        usually manages to finish in less than 24 hours.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/personal-privacy-03.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: Do you need anything from me before you begin transferring my website?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: We do not require any information that is personal. We will require, though, the login info for your present hosting account as well 
                        as the name of the site that you want to transfer.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>
            
            <div className="sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/trust-04.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: My old hosting account has delicate info inside. How can I rely on you?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: Customer privacy is among our main priorities – this is why, both our payment page and our Control Panel login page are protected 
                        with enterprise–class SSLs. We employ safety measures, which include, but are not limited to: physical, electronic and 
                        administrative operations to protect the information that we gather online. And we also have a detailed and rigid 
                        Privacy Policy, which specifies the types of information that we collect.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/website-work-05.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: Will there be any outage for my website?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: Nope. Your site will continue to be available online during the transfer. Once everything is completed on our end, 
                        we’ll notify you so that you can examine it and check for problems. If everything is alright, all you will need to 
                        do is edit your domain’s DNS settings.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/old-provider-06.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: Will my present hosting provider need to provide any information/assistance?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: No. We’ll never get in touch with your web hosting provider about anything. They won’t be aware of the web site transfer either.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-t-2 border-gray-200">
               
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-t-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/linux-based-07.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Q: Does it matter what platform my current hosting provider is using?</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        A: We support all Linux–driven hosting platforms. Essentially, if we can gain access to your Control Panel, we will be able to 
                        transfer your site.The only platforms that we don’t support are closed–source web site management platforms like Wix, BaseKit, 
                        Jimdo, Website Tonight, Four Square, Mr Site, etc.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden border-t-2 border-gray-200">
               
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default WebsiteMigrationPage
